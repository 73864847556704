import React, { useEffect, useState } from 'react';
import { PageContext } from '../../lib/tangenta/page-context';
import connector from '../../lib/tangenta/firebase/firebase-connector';
import Loading from '../Loading';
import { IStripeProfile } from '../tangenta/ConnectStripeAction';
import { basicDebounce, getSearchParams } from '../../lib/util';
import { redirect } from '../../Pager';
import { signInConfig } from '../../cfg/tangenta';
// import {StripeProvider} from 'react-stripe-elements';
// const clientId = 'ca_H7a2SAawdOTlJWG4A7ZF3p4SUsvgeOB4';


async function getAccountCredentials(userId: string, code: string, setMessage: any) {
  let profile: IStripeProfile | null = await connector.read(`StripeProfile/${userId}`);
  if (!profile) {
    profile = {
      id: userId,
      code,
      livemode: false,
      stripe_publishable_key: '',
      refresh_token: '',
      access_token: '',
      stripe_user_id: '',
      error: '',
      error_description: ''
    };
  }
  connector.listen(`StripeProfile/${userId}`, async (stripeProfile: IStripeProfile) => {
    if (stripeProfile.error) {
      setMessage(`${stripeProfile.error}: ${stripeProfile.error_description}`);
      return;
    }
    await connector.update(`StylistProfile/${userId}`, { stripe: true });
    setMessage('Account Connected');
    basicDebounce(() => {
      redirect(signInConfig.successUrl.substring(2));
    })
  });
  await connector.write(`StripeProfile/${userId}`, profile);

}

const Banking: React.FC<{ ctx: PageContext }> = ({ ctx }) => {
  const [message, setMessage] = useState('Connecting to Stripe');
  useEffect(() => {
    const params = getSearchParams();
    // console.log({ params });
    if (params.code && params.state && params.state === ctx.user!.id) {
      setMessage('Connecting Stripe Account');
      getAccountCredentials(ctx.user!.id, params.code, setMessage);
    } else {
      setMessage('Error connecting to Stripe');
    }
  }, [ctx]);
  return (
    <Loading message={message} />
    // <>
    //   <div>Banking Custom Component</div>
    //   <a href={`https://connect.stripe.com/oauth/authorize?response_type=code&amp;client_id=${clientId}&amp;scope=read_write`} className="connect-button"><span>Connect with Stripe</span></a>
    // </>
  )
};

export default Banking;