import React, { useState } from 'react';
import { PageContext } from '../../lib/tangenta/page-context';
import { Button, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import { getUdsLoginToken, setUdsLoginToken } from '../../lib/uds';

const EnableAutoLoginAction: React.FC<{ ctx: PageContext }> = ({ ctx }) => {
  const token = getUdsLoginToken();
  console.log({ token });
  const [promptOpen, setPromptOpen] = useState(false);
  const [password, setPassword] = useState('');
  function onClick() {
    if (token) {
      setUdsLoginToken('');
    } else {
      setPromptOpen(true);
    }
  }
  function handleClose() {
    setPromptOpen(false);
  }
  function setToken() {
    const token = `${ctx.user!.email}:${password}`;
    setUdsLoginToken(token);
    setPromptOpen(false);
    ctx.nav('home-mobile');
  }
  return (
    <>
      <Button onClick={onClick} variant='outlined'>
        <Checkbox checked={Boolean(token)} disabled style={{ margin: 0, padding: 0, marginRight: 5 }} />
        Authorize Device
      </Button>
      <Dialog
        open={promptOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Your Password</DialogTitle>
        <DialogContent>
          <TextField fullWidth label='Password' inputProps={{ type: 'password' }} value={password} onChange={(e) => setPassword(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={!password} onClick={setToken} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EnableAutoLoginAction;