import React from 'react';
import { IPage } from '../Pager';
import { Typography, Grid, makeStyles, Theme, createStyles, Button, Hidden } from '@material-ui/core';
import Icon, { IconType } from '../components/tangenta/Icon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      backgroundImage: 'url("/home-bg.jpg")',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundSize: 'cover',
      margin: 0,
      // padding: theme.spacing(5),
      overflow: 'hide',
    },
    container: {
      backgroundColor: 'rgba(250,250,250, 0.7)',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundSize: 'cover',
      margin: 0,
      // padding: theme.spacing(5),
      overflow: 'hide',
    }
  }),
);

const Home: React.FC<{}> = () => {
  const classes = useStyles();
  // useEffect(() => {
  //   if (localStorage.getItem('inviteFromStylistId')) {      
  //     localStorage.removeItem('inviteFromStylistId');
  //   }
  // });
  return (
    <div className={classes.background}>
      <Grid container className={classes.container} direction='column' alignItems='center' spacing={0} >
        <Grid item>
          <Typography variant='h6'>
          </Typography>
        </Grid>
        <Grid item style={{ paddingTop: 20, width: '100%' }}>
          <Grid container direction='row' alignItems='flex-start' alignContent='space-around'>
            <Grid item sm={5} xs={5} style={{ paddingTop: '10%', textAlign: 'right' }}>
              <Button style={{ color: '#222', fontWeight: 'bold', borderRadius: 10 }} variant='outlined' href='/#/login'
                startIcon={<Icon style={{ width: 32, height: 32 }} type={IconType.Login} />} >
                Enter
              </Button>
            </Grid>
            <Grid item xs={1} sm={1}>
              <Hidden xsDown>
                <div style={{ background: '#222', width: 5, height: 300, marginLeft: '50%' }} />
              </Hidden>
            </Grid>
            <Grid item style={{ textAlign: 'left', paddingTop: '10%', paddingBottom: '10%' }} xs={5} sm={5}>
              <img alt='logo' style={{ width: '40%', marginRight: 20 }} src='/logo-stacked.png' />
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ textAlign: 'center', padding: 10, width: '100%', margin: 0, paddingTop: 50, paddingBottom: 20 }}>
          <Typography variant='h5'>
            Helping Stylists and Clients to Connect Professionally and Benefit Financially
        </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

const page: IPage = {
  PageComponent: Home,
  path: '/',
  exact: true
};

export default page;