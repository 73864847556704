export class Notifier {
  public updaters:any[] = [];
  public notifyUpdaters(data:any) {
    for (const updater of this.updaters) {
      updater(data);
    }
  }
  public addUpdater(updater: any) {
    this.updaters.push(updater);
  }
  public removeUpdater(updater: any) {
    const index = this.updaters.indexOf(updater);
    if (index >= 0) {
      this.updaters.splice(index, 1);
    }
  }
}
