import React, { useEffect, useState } from 'react';
import { PageContext } from '../../lib/tangenta/page-context';
import { Button, Checkbox, CircularProgress } from '@material-ui/core';
import connector from '../../lib/tangenta/firebase/firebase-connector';

export interface IStripeProfile {
  id: string;
  code: string;
  stripe_publishable_key: string;
  livemode: boolean;
  stripe_user_id: string;
  refresh_token: string;
  access_token: string;
  error?: string;
  error_description?: string;
}

const emptyStripe: IStripeProfile = {
  id: '',
  code: '',
  stripe_publishable_key: '',
  livemode: false,
  stripe_user_id: '',
  refresh_token: '',
  access_token: ''
};

const ConnectStripeAction: React.FC<{ ctx: PageContext }> = ({ ctx }) => {
  const [stripe, setStripe] = useState(emptyStripe);
  const [loading, setLoading] = useState(true);
  const [urls, setUrls] = useState<any>({});
  useEffect(() => {
    connector.read(`StripeProfile/${ctx.user!.id}`).then(stripe => {
      if (stripe) {
        setStripe(stripe);
      }
      setLoading(false);
    });
    connector.read(`SystemConfig/urls`).then(setUrls);
  }, [ctx]);
  if (loading) {
    return <CircularProgress variant='indeterminate' />
  }
  if (stripe.access_token) {
    return (
      <Button variant='outlined'>
        <Checkbox checked={true} disabled style={{ margin: 0, padding: 0, marginRight: 5 }} />
        Connected to Stripe
      </Button>
    );
  }
  return (
    <Button href={`https://connect.stripe.com/oauth/authorize?response_type=code&state=${ctx.user!.id}&suggested_capabilities[]=transfers&client_id=${urls.stripeClientId}&scope=read_write`} variant='outlined'>
      <Checkbox checked={false} disabled style={{ margin: 0, padding: 0, marginRight: 5 }} />
      Connect to Stripe
    </Button>
  );
}

export default ConnectStripeAction;