import React, { useState } from 'react';
import { FormContext } from '../../lib/tangenta/form-context';
import { FormField } from '../../lib/tangenta/form-field';
import { makeStyles, Theme, createStyles, FormControl, FormLabel, IconButton, TextField } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import Icon, { IconType } from './Icon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(3),
      marginLeft: 0,
      width: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);
const MarkdownFormField: React.FC<{ ctx: FormContext, field: FormField, source: any, readonly?: boolean, simple?: boolean }> = ({ ctx, field, source, readonly, simple }) => {
  const classes = useStyles();
  const entityField = ctx.entity.fields.get(field.key);
  const [editable, setEditable] = useState(Boolean(!readonly));
  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">{field.label || entityField.key}</FormLabel>
      {!editable &&
        <ReactMarkdown source={source[field.key]} />
      }
      {editable &&
        <TextField
          multiline
          fullWidth
          rows={3}
          value={source[field.key]}
          onChange={(e) => ctx.setValue(field, e.target.value)}
        />
      }
      {!editable &&
        <IconButton onClick={() => setEditable(true)}>
          <Icon type={IconType.Edit} />
        </IconButton>
      }
      {(editable && !simple) &&
        <IconButton onClick={() => setEditable(false)}>
          <Icon type={IconType.Eye} />
        </IconButton>
      }
    </FormControl>
  )
}

export default MarkdownFormField;