import React from 'react';
import { FormContext } from '../../lib/tangenta/form-context';
import { FormField } from '../../lib/tangenta/form-field';
import { FormControlLabel, Checkbox } from '@material-ui/core';
const CheckboxFormField: React.FC<{ ctx: FormContext, field: FormField, source: any }> = ({ ctx, field, source }) => {
  const entityField = ctx.entity.fields.get(field.key);
  const checked = Boolean(source[field.key]);
  const toggleChecked = () => ctx.setValue(field, !checked);
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={toggleChecked} />}
      label={field.label || entityField.label}
    />
  )
}
export default CheckboxFormField;