import * as React from 'react';
import { FaUsers, FaUserCircle, FaQuestionCircle, FaUserCog, FaStripeS } from 'react-icons/fa';
import {
  AiOutlineSave,
  AiOutlinePlusCircle,
  AiOutlineEdit,
  AiOutlineLogin,
  AiOutlineLogout,
  AiOutlineHome,
  AiOutlineReload,
  AiFillCaretLeft,
  AiOutlineShareAlt,
  AiOutlineWifi,
  AiOutlineFolderOpen,
  AiOutlineCheckCircle,
  AiOutlineIdcard,
  AiOutlineQrcode,
  AiOutlineDollar,
  AiOutlineFileText,
  AiOutlineMail,
  AiOutlineCopy,
  AiOutlinePayCircle,
  AiOutlineMinusCircle
} from 'react-icons/ai';
import { IoMdBusiness } from 'react-icons/io';
import { FiXCircle, FiAlertOctagon, FiEye, FiSearch, FiUserPlus, FiChevronDown, FiShoppingCart, FiSettings, FiRotateCw, FiArrowRightCircle, FiRotateCcw } from 'react-icons/fi';

import {
  GoIssueOpened
} from 'react-icons/go';

export enum IconType {
  Save = 'save',
  Edit = 'edit',
  Add = 'add',
  Minus = 'minus',
  Login = 'login',
  Logout = 'logout',
  Home = 'home',
  IssueOpened = 'issue-opened',
  Users = 'users',
  User = 'user',
  Unknown = 'unknown',
  Reload = 'reload',
  CaretLeft = 'caret-left',
  Share = 'share',
  Scan = 'scan',
  Business = 'business',
  Remove = 'remove',
  Alert = 'alert',
  FolderOpen = 'folder-open',
  Check = 'check',
  IDCard = 'id-card',
  QrCode = 'qr-code',
  Eye = 'eye',
  Search = 'search',
  UserPlus = 'user-plus',
  ArrowDown = 'arrow-down',
  ShoppingCart = 'shopping-cart',
  Settings = 'settings',
  Money = 'money',
  RotateCw = 'rotate-cw',
  RotateCcw = 'rotate-ccw',
  File = 'file',
  NavNext = 'nav-next',
  Email = 'email',
  Support = 'support',
  Copy = 'copy',
  Pay = 'pay',
  Stripe = 'stripe'
}

const typeMap = {
  [IconType.Save]: AiOutlineSave,
  [IconType.Edit]: AiOutlineEdit,
  [IconType.Add]: AiOutlinePlusCircle,
  [IconType.Minus]: AiOutlineMinusCircle,
  [IconType.Login]: AiOutlineLogin,
  [IconType.Logout]: AiOutlineLogout,
  [IconType.Home]: AiOutlineHome,
  [IconType.IssueOpened]: GoIssueOpened,
  [IconType.Users]: FaUsers,
  [IconType.User]: FaUserCircle,
  [IconType.Unknown]: FaQuestionCircle,
  [IconType.Reload]: AiOutlineReload,
  [IconType.CaretLeft]: AiFillCaretLeft,
  [IconType.Share]: AiOutlineShareAlt,
  [IconType.Scan]: AiOutlineWifi,
  [IconType.Business]: IoMdBusiness,
  [IconType.Remove] : FiXCircle,
  [IconType.Alert]: FiAlertOctagon,
  [IconType.FolderOpen]: AiOutlineFolderOpen,
  [IconType.Check]: AiOutlineCheckCircle,
  [IconType.IDCard]: AiOutlineIdcard,
  [IconType.QrCode]: AiOutlineQrcode,
  [IconType.Eye]: FiEye,
  [IconType.Search]: FiSearch,
  [IconType.UserPlus]: FiUserPlus,
  [IconType.ArrowDown]: FiChevronDown,
  [IconType.ShoppingCart]: FiShoppingCart,
  [IconType.Settings]: FiSettings,
  [IconType.Money]: AiOutlineDollar,
  [IconType.RotateCw]: FiRotateCw,
  [IconType.RotateCcw]: FiRotateCcw,
  [IconType.File]: AiOutlineFileText,
  [IconType.NavNext]: FiArrowRightCircle,
  [IconType.Email]: AiOutlineMail,
  [IconType.Support]: FaUserCog,
  [IconType.Copy]: AiOutlineCopy,
  [IconType.Pay]: AiOutlinePayCircle,
  [IconType.Stripe]: FaStripeS

} as any;

interface IProps {
  type: IconType | string;
  style?: any;
}

const Icon: React.SFC<IProps> = ({ type, style }: IProps) => {
  if (!type) {
    return null;
  }
  const IconSvg = typeMap[type];
  if (!IconSvg) {
    return <FaQuestionCircle />
  }
  // const color = colorType || IconColorType.INHERIT;
  return <IconSvg style={style} />
}

export default Icon;