import React, { useRef } from 'react';
import { PageContext } from '../../lib/tangenta/page-context';
import { VisualElementIframe } from '../../lib/tangenta/visual-element-iframe';
import { detectAndEval } from '../../lib/util';

const IFrameElement: React.FC<{ ctx: PageContext, frame: VisualElementIframe }> = ({ ctx, frame }) => {
  const url = detectAndEval(frame.url, ctx);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  return (
    <iframe ref={iframeRef} style={{ width: '100%', height: '100%' }} title='iframe' src={url}></iframe>
  )
}

export default IFrameElement;