import { VisualElement, ElementType } from "./visual-element";

export class VisualElementSearch extends VisualElement {
  public targetQuery = '';
  public filter = false;
  public criteriaTemplate = '';
  public placeholder = 'search';
  public static register(map: Map<string, any>) {
    map.set(ElementType.Search, VisualElementSearch);
  }
}