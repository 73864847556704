import React, { } from 'react'
import { PageContext } from '../../lib/tangenta/page-context'
import { Avatar, Typography, Grid } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';

const StylistCard: React.FC<{ ctx: PageContext }> = ({ ctx }) => {
  const user = ctx.data.user;
  const stylist = ctx.data.stylist;
  if (!stylist) {
    return <div>no data available</div>
  }
  return (
    <div style={{ width: '100%' }}>
      <Grid container style={{ width: '100%' }} direction='row'>
        <Grid sm={5} xs={12} style={{ textAlign: 'center' }} item>
          <Typography variant='h4'>{user.name}</Typography>
          <Avatar style={{ width: 300, height: 300, margin: 'auto', marginTop: 30 }} src={user.photoUrl} />
        </Grid>
        <Grid sm={7} xs={12} style={{ paddingTop: 30 }} item>
          <Typography variant='h5'>{stylist.salon}</Typography>
          <Typography variant='h5'>{stylist.city}, {stylist.state}</Typography>
          <ReactMarkdown source={stylist.description} />
          <ReactMarkdown source={stylist.invite} />
        </Grid>
      </Grid>
    </div>
  )
}

export default StylistCard;