import React from 'react';
import { VisualElement, ElementType } from '../../lib/tangenta/visual-element';
import { PageContext } from '../../lib/tangenta/page-context';
import FormElement from './FormElement';
import { Form } from '../../lib/tangenta/form';
import ListElement from './ListElement';
import { VisualList } from '../../lib/tangenta/visual-list';
import TableElement from './TableElement';
import { VisualTable } from '../../lib/tangenta/visual-table';
import ComponentBranch from '../custom/ComponentBranch';
import { VisualElementCustom } from '../../lib/tangenta/visual-element-custom';
import StackElement from './StackElement';
import { VisualElementStack } from '../../lib/tangenta/visual-element-stack';
import { VisualElementFormSection } from '../../lib/tangenta/visual-element-form-section';
import FormSectionElement from './FormSectionElement';
import SearchElement from './SearchElement';
import { VisualElementSearch } from '../../lib/tangenta/visual-element-search';
import MarkdownElement from './MarkdownElement';
import { VisualElementMd } from '../../lib/tangenta/visual-element-md';
import IFrameElement from './IFrameElement';
import { VisualElementIframe } from '../../lib/tangenta/visual-element-iframe';

export function renderElement(e: VisualElement, pageContext = PageContext.current) {
  const newKey = `${e.key}-${Date.now()}`;
  switch (e.type) {
    case ElementType.Form:
      return <FormElement key={newKey} pageContext={pageContext!} form={e as Form} />
    case ElementType.List:
      return <ListElement key={newKey} pageContext={pageContext!} list={e as VisualList} />
    case ElementType.Table:
      return <TableElement key={newKey} pageContext={pageContext!} table={e as VisualTable} />
    case ElementType.Custom:
      return <ComponentBranch key={newKey} componentName={(e as VisualElementCustom).source} ctx={pageContext!} />
    case ElementType.Stack:
      return <StackElement key={newKey} pageContext={pageContext!} stack={e as VisualElementStack} />
    case ElementType.Md:
      return <MarkdownElement key={newKey} ctx={pageContext} markdown={e as VisualElementMd} />
    case ElementType.FormSection:
      return <FormSectionElement key={newKey} pageContext={pageContext!} section={e as VisualElementFormSection} />
    case ElementType.Search:
      return <SearchElement key={newKey} ctx={pageContext} search={e as VisualElementSearch} />
    case ElementType.IFrame:
      return <IFrameElement key={newKey} ctx={pageContext} frame={e as VisualElementIframe} />
    default:
      return <div key={newKey}>Unknown Element</div>;
  }
}