import { VisualElement, ElementType } from './visual-element';
import { STRINGIFIED } from './base';
export class VisualElementIframe extends VisualElement {
  public url = ''
  public static register(map: Map<string, any>) {
    map.set(ElementType.IFrame, VisualElementIframe);
  }
  public assignValue(k: string, proto: any) {
    if (k === STRINGIFIED) {
      this.url = proto;
    } else {
      super.assignValue(k, proto);
    }
  } 
}