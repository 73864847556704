import React, { } from 'react';
import { makeStyles, Theme, createStyles, IconButton, Paper, TableContainer, Toolbar, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
import { FormContext } from '../../lib/tangenta/form-context';
import { FormField } from '../../lib/tangenta/form-field';
import Icon, { IconType } from './Icon';
import { genNewRecord } from '../../lib/tangenta/query';
import { Record } from '../../lib/tangenta/record';
import { FormType } from '../../lib/tangenta/form';
import { generateFormField } from './FormElement';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    buttonBar: {
      width: '100%',
      textAlign: 'right',
      paddingRight: 0,
      marginRight: -5,
    },
    commandCell: {
      width: 50,
      maxWidth: 50,
      padding: 0,
      paddingRight: 0
    }
  }),
);

const LineFormRow: React.FC<{ ctx: FormContext }> = ({ ctx }) => {
  const classes = useStyles();
  function removeRow() {
    ctx.record.removeFromParent();
    ctx.pageContext.isDirty = true;
    ctx.pageContext.notifier.notifyUpdaters({ ...ctx.pageContext });
  }
  return (
    <TableRow>
      {ctx.form.fields.array.map(f => <TableCell key={f.key}>{generateFormField(ctx, f, ctx.entity.fields.get(f.key), ctx.record.current)}</TableCell>)}
      <TableCell style={{ paddingRight: 0 }} className={classes.commandCell}>
        <IconButton onClick={removeRow}><Icon type={IconType.Remove} /></IconButton>
      </TableCell>
    </TableRow>
  )
}

const SubarrayElement: React.FC<{ ctx: FormContext, field: FormField, source: any }> = ({ ctx, field }) => {
  const classes = useStyles();
  const entityField = ctx.record.entity.fields.get(field.key);
  const recordEntityKey = ctx.record.entity.fields.get(field.key).entity;
  const parentEntity = ctx.pageContext.schema.entities.get(recordEntityKey);
  const form = field.form;
  form.formType = FormType.TableRow;
  function addRow() {
    const newRecord = genNewRecord(parentEntity, ctx.pageContext);
    const newSubarray = [...(ctx.record.current[field.key] || []), newRecord];
    ctx.setValue(field, newSubarray);
    ctx.record.notifyUpdaters();
  }
  const lines: FormContext[] = ctx.record.mergeSubarrayRecords(field.key).map((record: Record, index: number) => {
    const formCtx = new FormContext(ctx.pageContext, form, record.entity);
    formCtx.record = record;
    return formCtx;
  });
  const headers = form.fields.array.map(formField => ({ formField, field: parentEntity.fields.get(formField.key) }));
  return (
    <div style={{ padding: 0 }}>
      <TableContainer className={classes.container} component={Paper}>
        <Toolbar style={{ borderBottom: '1px solid black' }}>
          <Typography variant='caption'>{entityField.label}</Typography>
        </Toolbar>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {headers.map(h => <TableCell component='th' key={h.formField.key}>{h.field.label}</TableCell>)}
              <TableCell className={classes.commandCell} style={{ paddingRight: 0 }} component='th' />
            </TableRow>
          </TableHead>
          <TableBody>
            {lines.map((lf, i) => <LineFormRow key={i} ctx={lf} />)}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.buttonBar}>
        <IconButton size='medium' style={{ marginRight: -10 }} onClick={addRow}><Icon type={IconType.Add} /></IconButton>
      </div>
    </div>
  )
}

export default SubarrayElement;