import React, { useEffect, useState } from 'react';
import { PageContext } from '../../lib/tangenta/page-context';
import { getSearchParams } from '../../lib/util';
import Loading from '../Loading';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import connector from '../../lib/tangenta/firebase/firebase-connector';
import { IPurchase, IItem, PurchaseStatus } from './purchase';

async function generateSuccessMessage(ctx: PageContext, sessionId: string, setter: any) {
  const purchase: IPurchase = (await connector.select('Purchase', ['clientId', '==', ctx.user!.id, 'checkoutSessionId', '==', sessionId], ''))[0];
  const item: IItem = await connector.read(`Item/${purchase.itemId}`);
  const stylist = await connector.read(`User/${purchase.stylistId}`);
  await connector.update(`Purchase/${purchase.id}`, { status: PurchaseStatus.SetupIntent });
  setter(`Thank you for purchasing ${item.name} from ${stylist.name}`);
  const connection = (await connector.select('Connection', ['clientId', '==', ctx.user!.id, 'stylistId', '==', purchase.stylistId], ''))[0];
  setTimeout(() => ctx.nav(`client-home/${connection.id}`), 2500);
}

async function redirectOnCancel(ctx: PageContext, sessionId: string, setter: any) {
  const purchase: IPurchase = (await connector.select('Purchase', ['clientId', '==', ctx.user!.id, 'checkoutSessionId', '==', sessionId], ''))[0];
  await connector.update(`Purchase/${purchase.id}`, { status: PurchaseStatus.Cancel });
  const connection = (await connector.select('Connection', ['clientId', '==', ctx.user!.id, 'stylistId', '==', purchase.stylistId], ''))[0];
  setTimeout(() => ctx.nav(`client-home/${connection.id}`), 2500);
}

const Checkout: React.FC<{ ctx: PageContext }> = ({ ctx }) => {
  const [message, setMessage] = useState('');
  useEffect(() => {
    const params = getSearchParams();
    if (params.cancel) {
      setMessage('Purchase has been canceled');
      redirectOnCancel(ctx, params.session_id, setMessage);
    } else if (params.session_id) {
      generateSuccessMessage(ctx, params.session_id, setMessage);
    }
  }, [ctx]);
  if (!message) {
    return <Loading message='Processing Payment' />;
  }
  return (
    <Grid container style={{ width: '100%' }}>
      <Grid item>
        <Typography style={{ textAlign: 'center' }} variant='h5'>
          {message}
        </Typography>
      </Grid>
      <Grid item style={{ marginLeft: 30 }}>
        <CircularProgress />
      </Grid>
    </Grid>
  )
}

export default Checkout;