import { VisualElement, ElementType } from './visual-element';
import { STRINGIFIED } from './base';
export class VisualElementMd extends VisualElement {
  public title = '';
  public markdown = '';
  public interpolate = true;
  public static register(map: Map<string, any>) {
    map.set(ElementType.Md, VisualElementMd);
  }
  public assignValue(k: string, proto: any) {
    if (k === STRINGIFIED) {
      this.markdown = proto;
    } else {
      super.assignValue(k, proto);
    }
  } 
}