import React from 'react';
import { PageContext } from '../../lib/tangenta/page-context';
import { VisualElementMd } from '../../lib/tangenta/visual-element-md';
import ReactMarkdown from 'react-markdown';
import { detectAndEval } from '../../lib/util';

const MarkdownElement: React.FC<{ ctx: PageContext, markdown: VisualElementMd }> = ({ ctx, markdown }) => {

  const md = markdown.interpolate ? detectAndEval(markdown.markdown, ctx) : markdown.markdown;
  return (
    <ReactMarkdown source={md} />
  )
}

export default MarkdownElement;