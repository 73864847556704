export enum BillingStatus {
  Manual = 'manual',
  Auto = 'auto',
  Suspended = 'suspended'
}

export interface IBilling {
  status: BillingStatus;
  lastRun: number;
  goodTransactions: number;
  failedTransactions: number;
}

export interface IItem {
  id: string;
  name: string;
  description: string;
  price: number;
  fee: number;
  purchases: IPurchase[];
  active: boolean;
}

export enum PurchaseStatus {
  Test = 'test',
  Initial = 'initial',
  Checkout = 'checkout',
  SetupIntent = 'setup_intent',
  PaymentMethod = 'payment_method',
  Cancel = 'cancel',
  Error = 'error',
  Terminated = 'terminated'
}

export interface IPurchase {
  id: string;
  clientId: string;
  stylistId: string;
  itemId: string;
  status: PurchaseStatus;
  checkoutSessionId: string;
  setupIntentId: string;
  paymentMethodId: string;
  timestamp: number;
  nextCharge?: number;
  error?: string;
}

export enum TransactionType {
  Fail = 'fail',
  Subscription = 'subscription',
  Redeem = 'redeem',
  Credit = 'credit'
}

export interface ITransaction {
  id: string;
  type: TransactionType;
  purchaseId: string;
  clientId: string;
  stylistId: string;
  itemId: string;
  itemName: string;
  error?: string;
  timestamp: number;
  amount: number;
  transactionFee: number;
}