import React, { useState } from 'react';
import { PageContext } from '../../lib/tangenta/page-context';
import { Avatar, Typography, TextField, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { IAlert, AlertSeverity } from '../../lib/tangenta/alert';
import Icon, { IconType } from '../tangenta/Icon';
import { goBack } from '../../Pager';
import { ITransaction, TransactionType } from './purchase';
import connector from '../../lib/tangenta/firebase/firebase-connector';
import { waitDialog, clearDialog } from '../DialogBox';
import Autocomplete from '@material-ui/lab/Autocomplete';

const serviceOptions = [
  'Birthday Present',
  'Error Correction',
];

const amountOptions = [
  10,
  20,
  30,
  50,
  75,
  100
];


const Credit: React.FC<{ ctx: PageContext }> = ({ ctx }) => {
  const client = ctx.data.client;
  const [amount, setAmount] = useState(10.00);
  const [service, setService] = useState(serviceOptions[0]);
  const [alert, setAlert] = useState<IAlert | null>(null);
  function handleAmountChange(value: any) {
    // let amt = parseFloat(value);
    // if (isNaN(amt)) {
    //   amt = 0;
    // }
    setAmount(value);
    validate(value, service);
  }
  function handleServiceChange(value: string) {
    value = value || '';
    setService(value);
    validate(amount, value);
  }
  function validate(amount: number, service: string) {
    let message = ''
    if (amount <= 0) {
      message = 'Zero or negative amount is invalid';
    }
    if (service.length === 0) {
      message = 'Credit Description is required';
    }
    if (message) {
      setAlert({
        message,
        severity: AlertSeverity.Error
      });
    } else {
      setAlert(null);
    }
  }
  async function createTx() {
    waitDialog('Posting transaction');
    const tx: ITransaction = {
      id: `${ctx.user!.id}${ctx.data.client.id}${Date.now()}`,
      clientId: ctx.data.client.id,
      stylistId: ctx.user!.id,
      amount,
      itemId: '',
      transactionFee: 0,
      itemName: service,
      timestamp: Date.now(),
      type: TransactionType.Redeem,
      purchaseId: ''
    };
    console.log({ tx });
    await connector.write(`Transaction/${tx.id}`, tx);
    clearDialog();
    goBack();
  }
  return <div style={{ width: '100%' }}>
    <div style={{ height: 64 }}>
      {Boolean(alert) &&
        <Alert severity={alert!.severity as any}>{alert!.message}</Alert>
      }
    </div>
    <div style={{ textAlign: 'center', marginTop: 10 }}>
      <Avatar src={client.photoUrl} style={{ width: 200, height: 200, margin: 'auto' }} />
      <Typography variant='h6'>Give credit to {client.name}</Typography>
    </div>
    <Autocomplete
      style={{ marginTop: 25 }}
      onChange={(e: any, v: any) => handleAmountChange(v)}
      options={amountOptions}
      autoSelect={true}
      getOptionLabel={(option) => `${option}`}
      forcePopupIcon={true}
      freeSolo={true}
      value={amount}
      renderInput={(params) => <TextField {...params} type='number' fullWidth onChange={(e: any) => handleAmountChange(e.target.value)} value={amount} label='Amount' variant="outlined" />}
    />
    {/* <TextField variant='outlined' style={{ marginTop: 25 }} onChange={handleAmountChange} label='Redeem Amount, $' value={amount} inputProps={{ type: 'number' }} fullWidth /> */}
    <Autocomplete
      style={{ marginTop: 25 }}
      onChange={(e: any, v: any) => handleServiceChange(v)}
      options={serviceOptions}
      autoSelect={true}
      getOptionLabel={(option) => option}
      forcePopupIcon={true}
      freeSolo={true}
      value={service}
      renderInput={(params) => <TextField {...params} fullWidth onChange={(e: any) => handleServiceChange(e.target.value)} value={service} label="Credit" variant="outlined" />}
    />
    <div style={{ marginTop: 64, textAlign: 'right' }}>
      <IconButton onClick={() => goBack()}>
        <Icon style={{ width: 64, height: 64 }} type={IconType.Remove} />
      </IconButton>
      <IconButton onClick={() => createTx()} disabled={Boolean(alert)}>
        <Icon style={{ width: 64, height: 64 }} type={IconType.Check} />
      </IconButton>
    </div>
  </div>
}

export default Credit;