import React, { useEffect, useState } from 'react';
import { PageContext } from '../../lib/tangenta/page-context';
import Loading from '../Loading';
import connector from '../../lib/tangenta/firebase/firebase-connector';
import { Grid, Typography, Button } from '@material-ui/core';
import { getStripe } from './stripe';
import { log } from '../../lib/tangenta/alert';
import { IItem, IPurchase, PurchaseStatus } from './purchase';
import { waitDialog, confirmDialog } from '../DialogBox';
import moment from 'moment';
import Icon, { IconType } from '../tangenta/Icon';

export function toCurrency(value: any) {
  const n = parseFloat(value);
  return n.toFixed(2);
}

async function loadItems(stylistId: string, set: any, ctx: PageContext) {
  // const stripe = await getStripe();
  // console.log({ stripe });
  // console.log({ stylistId, stylist });
  const items: IItem[] = await connector.select('Item', ['active', '==', 'true'], '');
  await connector.select('Purchase',
    ['clientId', '==', ctx.user!.id, 'status', '==', PurchaseStatus.PaymentMethod]
    , '');
  const purchases: IPurchase[] = await connector.select('Purchase',
    ['clientId', '==', ctx.user!.id, 'status', '==', PurchaseStatus.PaymentMethod]
    , '');
  for (const item of items) {
    item.purchases = purchases.filter(p => p.stylistId === stylistId && p.itemId === item.id);
  }
  console.log({ items });
  set(items);
}

const ItemEntry: React.FC<{ ctx: PageContext, item: IItem, stylistId: string }> = ({ ctx, item, stylistId }) => {
  async function startSubscription(itemId: string, stylistId: string) {
    // https://stripe.com/docs/payments/checkout/collecting
    waitDialog('Connecting to Stripe');
    const purchase: IPurchase = {
      id: `${ctx.user!.id}${stylistId}${itemId}${Date.now()}`,
      stylistId,
      clientId: ctx.user!.id,
      itemId,
      status: PurchaseStatus.Initial,
      checkoutSessionId: '',
      setupIntentId: '',
      paymentMethodId: '',
      timestamp: Date.now()
    };
    const unhook = connector.listenCriteria('Purchase',
      ['clientId', '==', purchase.clientId, 'id', '==', purchase.id],
      async (purs: IPurchase[]) => {
        try {
          const pur = purs[0];
          if (pur.status === PurchaseStatus.Checkout && pur.checkoutSessionId) {
            unhook();
            const stripe = await getStripe();
            if (stripe) {
              const { error } = await stripe.redirectToCheckout({ sessionId: pur.checkoutSessionId });
              if (error) {
                log.error(new Error(`Stripe Error ${error}`));
              }
            } else {
              log.error(new Error('redirect to Stripe Checkout Failed'));
            }
          }
        } catch (exc) {
          log.error(exc);
        }
      });
    await connector.write(`Purchase/${purchase.id}`, purchase);
  }
  function cancelSubscription(subscriptionId: string) {
    confirmDialog('Confirm cancellation', 'This will cancel your subscription, proceed?', async () => {
      await connector.update(`Purchase/${subscriptionId}`,
        { status: PurchaseStatus.Terminated, timestamp: Date.now() });
      ctx.notifier.notifyUpdaters({ ...ctx });
    });
  }
  function getDescriptionText() {
    if (item.purchases.length === 0) {
      return item.description;
    }
    const purchase = item.purchases[0];
    if (!purchase.nextCharge || purchase.nextCharge < Date.now()) {
      return 'Transaction Pending - your balance will be updated soon.';
    } else if (purchase.error) {
      return `Issue with your payment method: ${purchase.error}`;
    } else {
      return `Your next charge will be on ${moment(purchase.nextCharge).format('MM/DD/YY HH:mm')}`
    }
  }
  return (
    <Grid container direction='row' style={{ marginTop: 32, width: '100%' }}>
      <Grid xs={8} item>
        <Typography variant='body1'>
          {item.name}
        </Typography>
        <Typography variant='caption'>
          {getDescriptionText()}
        </Typography>
      </Grid>
      <Grid xs={4} style={{ textAlign: 'right' }} item>
        {item.purchases.length === 0 &&
          <Button onClick={() => startSubscription(item.id, stylistId)} variant='outlined'>
            Subscribe ${toCurrency(item.price)}
          </Button>
        }
        {item.purchases.length > 0 &&
          <Button startIcon={<Icon type={IconType.Check} />} onClick={() => cancelSubscription(item.purchases[0].id)} variant='outlined'>
            Subscribed
          </Button>
        }
      </Grid>
    </Grid>
  )
}

const Purchases: React.FC<{ ctx: PageContext }> = ({ ctx }) => {
  // const [state, setState] = useState(ctx);
  const [items, setItems] = useState<IItem[]>([]);
  const [message, setMessage] = useState('');
  const [connection, setConnection] = useState<any>();
  const [balance, setBalance] = useState(0);
  useEffect(() => {
    const updater = async () => {
      const connectionId = ctx.params[0];
      const connection = ctx.data.connections.find((c: any) => c.id === connectionId);
      if (!connection) {
        setMessage(`Use 'Find Stylist' to connect with your stylist`);
        return;
      }
      setConnection(connection);
      setMessage('');
      const stylist = await connector.read(`StylistProfile/${connection.stylistId}`);
      if (!stylist.stripe) {
        setMessage(`This stylist is not offering any subscriptions yet`);
        return;
      }
      // const user = ctx.user!;
      // if (!user.status.includes(UserStatus.Verified)) {
      //   setMessage(`You have to verify your email and sign in again. Please check email for verification message. You may have to check your spam or junk folder.`);
      //   return;
      // }
      await loadItems(connection.stylistId, setItems, ctx);
      const transactions = await connector.select('Transaction', ['clientId', '==', connection.clientId, 'stylistId', '==', connection.stylistId], 'timestamp');
      // console.log({ transactions });
      const total = transactions.reduce((prev, cur) => prev + parseFloat(cur.amount || 0), 0);
      setBalance(total.toFixed(2));
    };
    updater();
    ctx.notifier.addUpdater(updater);
    return () => ctx.notifier.removeUpdater(updater);
  }, [ctx]);
  if (message) {
    return <Typography style={{ marginTop: 30, textAlign: 'center', width: '100%' }} variant='h5'>{message}</Typography>
  }
  if (!connection) {
    return <Loading />
  }
  return (
    <>
      <div style={{ border: '1px solid #888', borderRadius: 14, width: '100%', padding: 10 }}>
        <Typography variant='h5'>Available Balance: ${balance} </Typography>
      </div>
      <div style={{ width: '100%' }}>
        {items.map(item => <ItemEntry key={`${item.id}-${Date.now()}`} item={item} ctx={ctx} stylistId={connection!.stylistId} />)}
      </div>
    </>
  )
}

export default Purchases;