import { VisualContainer } from "./visual-container";
import { ElementType } from "./visual-element";

export enum StackOrientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal'
}

export class VisualElementStack extends VisualContainer {
  public orientation = StackOrientation.Vertical;
  public static register(map: Map<string, any>) {
    map.set(ElementType.Stack, VisualElementStack);
  }
}