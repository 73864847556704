import { VisualElement, ElementType } from "./visual-element";
import { Form } from "./form";
import { ISchema } from "./ischema";

export class VisualElementFormSection extends VisualElement {
  public form = new Form();
  public label = '';
  public expanded = false;
  public visible = '';
  public static register(map: Map<string, any>) {
    map.set(ElementType.FormSection, VisualElementFormSection);
  }
  public validate(schema: ISchema): string {
    this.form.parent = this;
    return this.form.validate(schema);
  }
}