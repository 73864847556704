import React, { useEffect } from 'react';
import { IPage, redirect } from '../Pager';
import Loading from '../components/Loading';

const Invite: React.FC<{ stylistId: string }> = ({ stylistId }) => {
  localStorage.setItem('inviteFromStylistId', stylistId);
  useEffect(() => {
    setTimeout(() => redirect('/app/1/home'), 500);
  }, [stylistId]);
  return (
    <Loading message='Creating Connection' />
  )
}

const page: IPage = {
  PageComponent: Invite,
  path: '/invite/:stylistId',
  exact: true
};

export default page;